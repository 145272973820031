(($)=>{
	$(()=>{

		$.getJSON('http://www.ovrebjornterrassen.se/api/data-jsonp.php?callback=?', (response)=>{
			
			const items = response.result;
			
			let sortedList = []; 
			
			Object.keys(items).map((key)=>{
				const item = items[key];
				if(sortedList[item.floor] === undefined) sortedList[item.floor] = [];
				sortedList[item.floor][item.house] = item;
			});

			Object.keys(sortedList).map((key)=>{
				const $group = $('.list-group').filter( (i,v)=> $(v).data('floor') === key );
				const $listGroup = $group.find('.list-group__items').html('');
				const newItems = sortedList[key];
				Object.keys(newItems).map((key)=>{
					$(getItemHTML(newItems[key])).appendTo($listGroup);
				});
			});
		});
	});



	function getItemHTML(item){
		
		const {area, beds, floors, house, floor, order, price, status} = item;

		function getInfo(){
			const reservedText = status === 'booked' ? '<span class="status-text">(Bokad)</span>' : ''; 
			return `
			<div class="apartment__info">
					<span class="beds info-bullet">${beds} bäddar</span>
					<span class="stories info-bullet">${floors} plan</span>
					<span class="area info-bullet">${area} kvm</span>
					<span class="price">${price.toLocaleString('sv-SE')} kr</span>
					${ reservedText }
			</div>`;
		}

		function getButton(){
			const fileName = floor.replace('ö', 'o') + '_' + house + '.pdf';
			if(status === 'sold'){
				return	'<div class="apartment__more-info--sold">Såld</div>';
			}else{
				return `<span class="apartment__more-info-btn"><a href="/src/assets/pdfs/${fileName}" target="_blank" class="btn btn--default btn--invert">Planlösning</a></span>`;
			}
		}
		
		function passiveIcon(house){
			let yOffset = 0;
			switch(house){
				case 'b':
					 yOffset = 17;
				break; 
				case 'c':
					 yOffset = 34; 
				break; 
				case 'd':
					 yOffset = 51; 
				break; 
			} 
			return `
			<g class="house house--${house}" stroke="#D2D2D2" transform="translate(${yOffset}, 0)">
			   <use id="dummy_house" xlink:href="#dummy_house"></use>
			</g>
			`;
		}

		function passiveIcons(houses){
			let html = houses.map( (v)=> passiveIcon(v) ).join();
			return html;
		}

		function getSvgIcon(house){

			let activeIcon = `
				<path class="house__top" vector-effect="non-scaling-stroke" d="M3,16 L14,16 L14,10.9814166 C13.7805866,8.17054264 11.3833317,6 8.5005431,6 C5.61775452,6 3.22049965,8.17054264 3,10.9814166 L3,16 Z"></path>
		    <polygon class="house__middle" vector-effect="non-scaling-stroke" points="3 26 14 26 14 19 3 19"></polygon>
		    <polygon class="house__bottom" vector-effect="non-scaling-stroke" points="3 40 14 40 14 29 3 29"></polygon>
		    <polyline class="house__conture" vector-effect="non-scaling-stroke" points="17 40 0 40 0 18.8559251 0 6.93399136 8.5 0 17 6.93399136 17 18.8559251 17 40 0 40"></polyline>
			`;

			let icons = `
				${ passiveIcons( 'bcd'.split('') ) }
				<g class="house house--current house--a" stroke="#D2D2D2">
				    ${activeIcon}
				</g>
			`;

			switch(house){
				
				case 'b':
					icons = `
						${ passiveIcons( 'acd'.split('') ) }
						<g class="house house--current house--b" stroke="#D2D2D2" transform="translate(17.000000, 0.000000)">
						    ${activeIcon}
						</g>`;
				break;
				
				case 'c':
					icons = `
						${ passiveIcons( 'abd'.split('') ) }
						<g class="house house--current house--c" stroke="#D2D2D2" transform="translate(34.000000, 0.000000)">
						    ${activeIcon}
						</g>`;
				break;

				case 'd':
					icons = `
						${ passiveIcons( 'abc'.split('') ) }
						<g class="house house--current house--d" stroke="#D2D2D2" transform="translate(51.000000, 0.000000)">
						   ${activeIcon}
						</g>`;
				break;
			}

			
			return `
			<svg class="icon-apartments" viewBox="-1 -1 70 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		    <title>Lägenheter översikter</title>
		    
		    <symbol id="dummy_house">
          <path class="house__top" vector-effect="non-scaling-stroke" d="M3,16 L14,16 L14,10.9814166 C13.7805866,8.17054264 11.3833317,6 8.5005431,6 C5.61775452,6 3.22049965,8.17054264 3,10.9814166 L3,16 Z"></path>
          <polygon class="house__middle" vector-effect="non-scaling-stroke" points="3 26 14 26 14 19 3 19"></polygon>
          <polygon class="house__bottom" vector-effect="non-scaling-stroke" points="3 40 14 40 14 29 3 29"></polygon>
          <polyline class="house__conture" vector-effect="non-scaling-stroke" points="17 40 0 40 0 18.8559251 0 6.93399136 8.5 0 17 6.93399136 17 18.8559251 17 40 0 40"></polyline>
		    </symbol>
		    
		    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
	        <g id="Icons/icn_house_listing">
            ${ icons }
	        </g>
		    </g>
			</svg>`;
		}

		function floorClass(floor){
			if(floor === 'sol') return 'floor-top';
			if(floor === 'snö') return 'floor-middle';
			if(floor === 'ski') return 'floor-bottom';
		}

		return `
		<div class="list-group__item">
			<div class="apartment house-${house} ${ floorClass(floor) } status-${status}">
				
				<span class="apartment__house"> 
						${house}
				</span>
				
				${ getInfo() }
				
				<div class="apartment__more-info">
					
					<div class="apartment__matrix">
						${ getSvgIcon(house) }
					</div>
					
					${ getButton() }

				</div>

			</div>
		</div>
`;
	}
})(jQuery);