(($)=>{

	const signup = {}; 

	const selector = {
		open: '.js--open-overlay',
		close: '.js--close-overlay',
		overlay: '.js--overlay'
	};

	const eventHandler = {
		
		onOpenBtnClick: (event)=>{
			event.preventDefault();
			helpers.openOverlay();
		},

		onCloseBtnClick: (event)=>{
			event.preventDefault();
			helpers.closeOverlay();
		},

		onSignupSuccess: (json, response) =>{
			$('.signup-form').fadeOut(600, ()=>{
				$('.signup-form__confirmation').removeClass('hidden').hide().fadeIn(600);
			});
		},
		
		onSignupError: (json, response, error) =>{
			console.log(json, response);
			switch(json.status){
				case 412:
					eventHandler.onUserAlreadyExists(); 
				break;
				default:
					alert('Anmälan fick ett tekniskt problem. Var god försök senare');
				break;
			}
		},

		onUserAlreadyExists: ()=>{
			const $emailField 				= $('#signup_form input[name="email"]');
			const $emailFieldWrapper 	= $emailField.parent('div');
			const errorMessageText 		= 'Den här e-postassen är redan registerad';
			const $errorMessage 			= $('<div>' + errorMessageText + '</div>').addClass('error error--already-registread');
			
			if( $('.error--already-registread').length > 0) return 
			
			$errorMessage.prependTo($emailFieldWrapper);	
			$emailField.addClass('error');
			
			$emailField.on('focus', ()=>{
				$('.error--already-registread').remove();
				$emailField.removeClass('error');
			})			
		}, 

		onLoad: ()=>{
			helpers.updateFieldsFromApi();
			helpers.addSelectric();
			
			signup.form = $('#signup_form').validate({
				rules: {
			    // simple rule, converted to {required:true}
			    name: "required",
			    phone: "required",
			    // compound rule
			    email: {
			      required: true,
			    	email: true
			    }
			  },
			  submitHandler: helpers.sendToMailchimp
		  });
		}
	};

	const helpers = {

		// In case we have a confirmation, we need to reset it to the 
		// form display
		resetOverlayLayout: ()=>{
			$('.signup-form__confirmation').addClass('hidden');
			$('.signup-form').show();
		},

		closeOverlay: ()=>{
			$(selector.overlay).find('form').trigger('reset');
			signup.form.resetForm();
			$(selector.overlay).fadeOut(()=>{setTimeout(helpers.resetOverlayLayout), 500});
		},

		openOverlay: ()=>{
			$(selector.overlay).removeClass('hidden').hide().fadeIn();
		},
		
		sendToMailchimp: (e)=>{
			const mcUrl = '/mc-ep.php'; // 'https://matsfahlander.us16.list-manage.com/subscribe/post-json?&c=?';
			
			const formData = $(selector.overlay).find('form').serializeArray(); // Fetch the data
			const mcData = {};
			$.each(formData, (i,v)=>{
				mcData[v.name] = v.value;
			});

			 $.ajax({
        url: mcUrl,
        type: 'POST',
        data: mcData,
        dataType: 'json',
        
        success: eventHandler.onSignupSuccess,

        error: eventHandler.onSignupError,
    	});

			return false; 
			e.preventDefault();
		},

		addSelectric(){
  		$('select').selectric();
		},

		updateFieldsFromApi (){
			$.getJSON('http://www.ovrebjornterrassen.se/api/data-jsonp.php?callback=?', (response)=>{
				
				
				const items = response.result;
				let statusList = items.map( (v) => { 
					return {id: v.floor + '-' + v.house, status: v.status }
				} );
				statusList.map( (v)=>{
					let $target = $('li.option-' + v.id );
					let prevVal = $target.text();
					let suffix = '';
					
					switch(v.status){
						case 'booked': 
						suffix = ' (Bokad)';
						break;

						case 'sold': 
						suffix = ' (Såld)';
						break;
					}
					$target.text( prevVal + suffix);
				});
			});
		}
	};


	$(()=>{
		eventHandler.onLoad();
		// Attached events
		$(selector.open).on('click', eventHandler.onOpenBtnClick);
		$(selector.close).on('click', eventHandler.onCloseBtnClick);
	});

})(jQuery);


// Custom messages in swedish
jQuery.extend(jQuery.validator.messages, {
  required: 'Detta f&auml;lt &auml;r obligatoriskt.',
  maxlength: jQuery.validator.format('Du f&aring;r ange h&ouml;gst {0} tecken.'),
  minlength: jQuery.validator.format('Du m&aring;ste ange minst {0} tecken.'),
  rangelength: jQuery.validator.format('Ange minst {0} och max {1} tecken.'),
  email: 'Ange en korrekt e-postadress.',
  url: 'Ange en korrekt URL.',
  date: 'Ange ett korrekt datum.',
  dateISO: 'Ange ett korrekt datum (&Aring;&Aring;&Aring;&Aring;-MM-DD).',
  number: 'Ange ett korrekt nummer.',
  digits: 'Ange endast siffror.',
  equalTo: 'Ange samma v&auml;rde igen.',
  range: jQuery.validator.format('Ange ett v&auml;rde mellan {0} och {1}.'),
  max: jQuery.validator.format('Ange ett v&auml;rde som &auml;r mindre eller lika med {0}.'),
  min: jQuery.validator.format('Ange ett v&auml;rde som &auml;r st&ouml;rre eller lika med {0}.'),
  creditcard: 'Ange ett korrekt kreditkortsnummer.',
});