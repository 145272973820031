window.obtApp = window.obtApp || {};
(($)=>{
	$(()=>{
		var mySwiper = new Swiper ('.swiper-container', {
	    loop: true,
	    speed: 300,
	    
	    keyboardControl: true,
	    effect: 'fade',

	    // Navigation arrows
	    nextButton: '.swiper-button-next',
	    prevButton: '.swiper-button-prev',
	  });        
		
		const $solid = $('.solid-slide');
		const $slider = $('.swiper-slide');

		if(mySwiper.on !== undefined){
			mySwiper.on('slideChangeStart', on.slideChangeStart);
		}
		window.obtApp.slider = mySwiper; 
	});
	

	const on = {
		slideChangeStart(Swiper){
			const i = Swiper.activeIndex;
			const currentSlide = $('.swiper-slide').eq(i).children('.act-slide');
			const label = currentSlide.data('label');
			setActiveLabelSolid(label);
			setActiveNavItem(label);
		}
	};

	// TODO: Make an Event which we can hook on method, 
	// since we only should do it when the label actually change
	function setActiveLabelSolid(label){
		$('.solid-slide').addClass('hidden');
		$('.solid-slide').filter( function(i,v){
			return $(this).data('label') === label;
		}).removeClass('hidden');
	}

	function setActiveNavItem(label){
		const $navItemLinks = $('.slide-nav__link');
		$navItemLinks.removeClass('active');
		$navItemLinks.filter( function(i,v){
			return $(this).data('label') === label;
		}).addClass('active');
	}



})(jQuery);