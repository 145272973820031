(($)=>{

	$(()=>{

		const $audio = $('#main_audio');
		const audioControl = $('.js--mute-toggle');
		const volume = Cookies.get('audio_loop');
		
		const helper = {
			iconMuted: ()=>{
				console.log('show muted icon');
				audioControl.find('.unmuted').addClass('hidden');
				audioControl.find('.muted').removeClass('hidden');
			}, 
			iconUnmuted: ()=>{
				audioControl.find('.unmuted').removeClass('hidden');
				audioControl.find('.muted').addClass('hidden');
			},
			turnOffSound: ()=>{
				Cookies.set('audio_loop', 'off');
			}, 
			turnOnSound: ()=>{
				Cookies.set('audio_loop', 'on');
			}
		};

		if(volume === 'off'){
			$('#main_audio').prop('volume', 0);
			helper.iconMuted();
		}

		$(window).on('navigateTrans', ()=>{
			$audio.animate({volume: 0}, 800);
		});

		$('.js--mute-toggle').on('click', (e)=>{
			e.preventDefault(); 
			const volume =  $('#main_audio').prop('volume'); 
			const newVolume = volume === 0 ? 1 : 0; 
			if(newVolume === 0){
				helper.iconMuted();
				helper.turnOffSound();
			}else{
				helper.iconUnmuted();
				helper.turnOnSound();
			}
			$audio.animate({volume: newVolume}, 400);
		});

		
	});



})(jQuery)