(($)=>{
	$(()=>{
		const $heroWrapper = $('.hero-video');
		const $heroWrapperClose = $('.hero-video__close');
		const $heroWrapperOpen = $('.hero-video__open');
		
		$heroWrapper.hide();	

		$heroWrapperClose.on('click', function(e){
			e.preventDefault()
			$heroWrapper.fadeOut(100);	
		});
		
		$heroWrapperOpen.on('click', function(e){
			e.preventDefault()
			$heroWrapper.fadeIn(100);	
		});
	});
})(jQuery);