window.obtApp = window.obtApp || {};

(($)=>{
	function init(){
		$('.slide-nav__items .slide-nav__item').eq(0).find('.slide-nav__link').addClass('active');
	}
	$(()=>{
		init();
		$('.slide-nav__link').on('click', function(e){
			e.preventDefault()
			const $item = $(this);
			const label = $item.data('label');
			
			const slideTarget = getFirstSlideOfLabel(label).data('slide-id');
			window.obtApp.slider.slideTo(slideTarget+1);
		});		
	});
	
	function getFirstSlideOfLabel(label){
		return $('.act-slide[data-label="'+label+'"]').eq(0); 
	};

})(jQuery);
