(($)=>{
	$(()=>{
		// Just make stuff show up!
		const $btnShare = $('.js--open-overlay-share');
		const $btnClose = $('.js--close-sharing');
		const $sharingOverlay = $('.js--overlay-sharing');

		$btnShare.on('click', function(){
			animateItems();
			$sharingOverlay.removeClass('hidden').hide().fadeIn(200);
		});
		$btnClose.on('click', function(){
			$sharingOverlay.fadeOut();
		});
		
		const $shareLink = $('.js--share');
		
		$shareLink.on('click', function(e){
			e.preventDefault();
			const type = $(this).data('type');
			let shareUrl;
			switch(type){
				
				case 'facebook':
					shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href;
					window.open(shareUrl, 'shareWindow', 'width=500,height=350');
					break;
				
				case 'twitter':
					shareUrl = 'http://twitter.com/intent/tweet?' + 
										 'text=' + encodeURIComponent(document.title) + 
										 '&url=' + encodeURIComponent(window.location.href);
					window.open(shareUrl, 'shareWindow', 'width=500,height=350');
					break;
				default:
					window.location.href = "mailto:?subject=Björnterrassen&body=" + window.location.href;
					break;
			}
		});

	});
	function animateItems(){
		var tl = new TimelineLite({delay: 0.2});
		tl.staggerFrom('.sharing__btns .sharing__btn', 0.3, {opacity: 0 }, 0.2);
		return tl; 
	}
})(jQuery);