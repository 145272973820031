(($)=>{
	const signup = {}; 
	const animationEndEvent = 'animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd';

	const selector = {
		navItems: '.js--nav-item'
	};

	const eventHandler = {
		onLoad: ()=>{
			helpers.positionLinks();
		},

		onNavItemsClick: (e)=>{
			e.preventDefault();
			const url = $(e.delegateTarget).attr('href');
			
			if($(e.delegateTarget).attr('target') === '_blank'){
				window.open(url, '_blank');
				return false;
			} 

			const redirect = helpers.redirect.bind(null, url); 

			if($('.ytplayer-container').length > 0){
				
				aniWhen.playerIsHidden()
					.then(aniWhen.outroIsDone)
					.then(aniWhen.slideIsAnimated)
					.then(redirect);
			}else{
				
				aniWhen.outroIsDone()
					.then(aniWhen.slideIsAnimated)
					.then(redirect);
			}
		}
	};

	const helpers = {
		
		redirect: (url)=>{
			window.location.href = url;
		},

		positionLinks: ()=>{
			const $items = $(selector.navItems);
			
			if($(window).width() < 960){
				$items.attr('style', 	'');
			
			}else{
				const $mainContent = $('.slide__body');
				const $mainContentPosition = $mainContent.offset();
				
				// These are defined here just in order to have access to the $mainContentPosition and other arbitrary vars.
				function positionAsLinkLeft($item){
					$item.hide().css({position: 'absolute', top: $mainContentPosition.top + 20, left: $mainContentPosition.left - 200}).fadeIn(200);
				}
				
				function positionAsLinkRight($item){
					$item.hide().css({position: 'absolute', top: $mainContentPosition.top + 20, left: $mainContentPosition.left + $mainContent.width() + 50}).fadeIn(200);	
				}

				function positionAsLinkBottom($item){
					$item.hide().css({position: 'absolute', top: $mainContentPosition.top + $mainContent.height() , left: $(window).width()/2 - 60 }).fadeIn(200);	
				}

				// Somewhat unortodox way to handle position
				$.each($items, (i,v)=>{
					const $item = $(v);
					
					if($item.hasClass('nav-item__link--left')){
						positionAsLinkLeft($item);
					}else if($item.hasClass('nav-item__link--right')){
						positionAsLinkRight($item);
					}else if($item.hasClass('nav-item__link--down')){
						positionAsLinkBottom($item);
					}else if($item.hasClass('nav-item__link--up')){
						positionAsLinkBottom($item);
					}
				});
				
				
			}	
		},
		
		animateOutro: (cb)=>{
			$(window).trigger('navigateTrans');
			$('.slide').animateCss('fadeOut');
			setTimeout(()=>{
				$('.slide').hide();
				cb();
			}, 200);
		},

		animateSlideBg: (cb) =>{
			$('.slide__bg').animate({opacity: 0}, 700, cb);	
			/*
			if($(window).width() < 770){
				$('.slide__bg').animate({opacity: 0}, 700, cb);	
				console.log('small')
			}else{
				$('.slide__bg').animate({backgroundSize: '130%', opacity: 0}, 700, cb);	
			}
			*/
			
		}

	};

	// A bunch of promise wrappers used for simpler chaining
	const aniWhen = {
		
		playerIsHidden: ()=> new Promise((resolve)=>{
			$('.ytplayer-container').fadeOut(400, resolve);
		}),
		
		outroIsDone: ()=> new Promise((resolve)=>{
			helpers.animateOutro(resolve);
		}),
		
		slideIsAnimated: ()=> new Promise((resolve)=>{
			helpers.animateSlideBg(resolve);
		}),
	}

	$(()=>{
		eventHandler.onLoad();
		$(window).on('resize', helpers.positionLinks);
		
		$(selector.navItems).on('click', eventHandler.onNavItemsClick);
	});

})(jQuery);
