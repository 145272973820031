(($)=>{
	


	const eventHandlers = {
		onLoad: function() {
			aside.init();
			
			// Dead simple click actions for acts
			$(document).on('click', '.js--aside-open', aside.open);
			$('.js--aside-close').on('click', aside.close);	
		}
	};

	$(eventHandlers.onLoad);	

	const aside = {
		init: function (){
			// Prepare the first slide in case we are not navigatiing around
			$('.text-solid .solid-slide').eq(0).removeClass('hidden');
		},
		
		hideSlideContent: function () {
			$('.act-slide__title').hide();
			$('.js--aside-open').hide(); 		// Hide them all
		},

		showSlideContent: function () {
			$('.act__main').animate({opacity: 0}, 200);
			$('.solid-slide').animate({opacity: 0}, 300);
		},

		open : (e)=>{
			e.preventDefault();
			$('.act__main').animate({opacity: 0}, 200);
			$('body').addClass('view--expanded');
			
			aside.hideSlideContent();
			
			$('.solid-slide').css('opacity', 0);

			setTimeout(()=>{
				$('.act__main').animate({opacity: 1}, 200);
				$('.act-slide__overlay').animate({opacity: 0}, 200);
				window.obtApp.slider.update(true);
				$('.solid-slide').animate({opacity: 1}, 400);
			}, 500);
		},

		close : function(e){
			e.preventDefault();
			
			aside.showSlideContent()
			
			setTimeout(()=>{
				$('body').removeClass('view--expanded');
				$('.act-slide__title').show();
				$('.js--aside-open').show(); // Hide them all
				setTimeout(()=>{
					window.obtApp.slider.update(true);
					$('.act-slide__overlay').animate({opacity: 0.4}, 200);
					$('.act__main').animate({opacity: 1}, 200);
				}, 500);
			}, 500);
		}
	};

	$(window).on('resize', function(){
		if($(this).width() < 780){
			$('.solid-slide').css({opacity: 1});	
		}
	});
})(jQuery);